//import 'firebase/auth';
//import firebase from 'firebase/app'
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

//import 'firebase/firestore';

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: "retroink-fire.firebaseapp.com",
	projectId: "retroink-fire",
	storageBucket: "retroink-fire.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
//console.log("firebase init");

const app = initializeApp(config);

const auth = getAuth(app);
const googleAuth = new GoogleAuthProvider();
const db = getFirestore(app);

// exportしてどこからでも使えるようにする
export {auth, googleAuth, db};

//firebase.initializeApp(config)
//export const googleAuth = new firebase.auth.GoogleAuthProvider();
//export const twitterAuth = new firebase.auth.TwitterAuthProvider();
//export const auth = firebase.auth();
//export const db = firebase.firestore();
