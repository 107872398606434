import React,{useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from './Theme';
import InputField from './InputField';
import {Inc,ChartData,MaxChartTitleLength,MaxChartIntroLength} from './Inc';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import CancelIcon from '@mui/icons-material/Cancel';

interface ConfigTitleProps {
	close():void,
	update(title:string,intro:string):void,
	title:string,
	intro:string,
	isOpen:boolean,
}

const ConfigTitle:React.FC<ConfigTitleProps> = (props)=> {
	const [data,setData] = useState({
		title:"",
		intro:"",
	});
	const [errors,setErrors] = useState(Inc.defaultChartDataError());
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));

	const changeValue = (name:keyof ChartData) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setData({...data,[name]:value})
	};
	const close = ()=>{
		props.close();
	}
	const update = ()=>{
		props.update(data.title,data.intro);
	}

	useEffect(()=>{
		if(props.isOpen){
			setData({title:props.title,intro:props.intro});
		}
	},[props.isOpen])

	if(!props.isOpen){
		return (null);
	}

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullScreen={fullScreen}
		>
			<DialogTitle id="yesno-dialog-title">タイトルとイントロの設定</DialogTitle>
			<DialogContent>
				<DialogContentText id="yesno-dialog-description">

				</DialogContentText>
				<InputField label="タイトル" onChange={changeValue("title")} value={data.title} error={errors.title} opt={{maxLength:MaxChartTitleLength}}/>
				<InputField label="イントロ" onChange={changeValue("intro")} value={data.intro} error={errors.intro} opt={{maxLength:MaxChartIntroLength,rows:4}}/>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
				いいえ
				</Button>
				<Button onClick={update} color="primary" variant="contained" autoFocus>
				はい
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfigTitle;