import React,{useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from './Theme';
import InputField from './InputField';
import useStyles from './Styles';
import {Inc,ChartData,MaxChartOutroLength,MaxChartWriterLength} from './Inc';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import CancelIcon from '@mui/icons-material/Cancel';

interface ConfigOutroProps {
	close():void,
	update(outro:string,writer:string,date:string):void,
	outro:string,
	writer:string,
	date:string,
	isOpen:boolean,
}

const ConfigOutro:React.FC<ConfigOutroProps> = (props)=> {
	const classes = useStyles();
	const [data,setData] = useState({
		outro:"",
		writer:"",
		date:"",
	});
	const [errors,setErrors] = useState(Inc.defaultChartDataError());
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));

	const changeValue = (name:keyof ChartData) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setData({...data,[name]:value})
	};
	const close = ()=>{
		props.close();
	}
	const update = ()=>{
		props.update(data.outro,data.writer,data.date);
	}

	useEffect(()=>{
		if(props.isOpen){
			setData({outro:props.outro,writer:props.writer,date:props.date});
		}
	},[props.isOpen])

	if(!props.isOpen){
		return (null);
	}

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullScreen={fullScreen}
		>
			<DialogTitle id="yesno-dialog-title">あとがき等の設定</DialogTitle>
			<DialogContent>
				<DialogContentText id="yesno-dialog-description">

				</DialogContentText>
				<InputField label="あとがき" onChange={changeValue("outro")} value={data.outro} error={errors.outro} opt={{maxLength:MaxChartOutroLength,rows:4}}/>
				<InputField label="この記事の作成者名" onChange={changeValue("writer")} value={data.writer} error={errors.writer} opt={{maxLength:MaxChartWriterLength}}/>
				<TextField
					id="input-date"
					fullWidth={true}
					onChange = {changeValue("date")}
					value={data.date}
					error={errors.date!==""}
					helperText={errors.date}
					type="date"
					variant="filled"
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						className:classes.formInput,
					}}
				/>				
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
				いいえ
				</Button>
				<Button onClick={update} color="primary" variant="contained" autoFocus>
				はい
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfigOutro;