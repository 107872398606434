import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import useStyles from './Styles';

interface InputFieldProps {
	label:string,
	value:string | number,
	error?:string,
	onChange:any,
	opt?:{[key:string]:any;},
}

const InputField:React.FC<InputFieldProps> = (props)=> {
	const classes = useStyles();

	let rows:number = 1;
	if( props.opt && props.opt["rows"] ){
		rows = props.opt["rows"];
	}
	let type:"text"|"time" = "text";
	if( props.opt && props.opt["type"] ){
		type = props.opt["type"];
	}

	let inputLabelProps:any = {};
	let inputProps:any = {};
	let placeholder = "";
	let required = false;
	let maxLength = 0;
	let rest = 0;
	let classCounter = classes.formCounter;
	let fullWidth = true;
	let disabled = false;
	if( props.opt ){
		if( props.opt["shrink"]===true ){
			inputLabelProps["shrink"] = true;
		}
		if( props.opt["startAdornment"] ){
			inputProps["startAdornment"] = props.opt["startAdornment"];
		}
		if( props.opt["endAdornment"] ){
			inputProps["endAdornment"] = props.opt["endAdornment"];
		}
		if( props.opt["maxLength"] ){
			maxLength = props.opt["maxLength"];
			rest = maxLength;
			const v = props.value as string;
			if( v ){
				rest -=  v.length;
				if( rest<0 ){
					classCounter += " " + classes.formCounterMinus;
				}
			}
		}
		if( props.opt["placeholder"] ){
			placeholder = props.opt["placeholder"];
		}
		if( props.opt["required"] ){
			required = props.opt["required"];
		}
		if( props.opt["fullWidth"]===false ){
			fullWidth = false;
		}
		if( props.opt["disabled"]===true ){
			disabled = true;
		}
	}
	inputProps["className"] = classes.formInput;

	return (
		<FormControl fullWidth={fullWidth}>
			<TextField
				label={props.label}
				variant="filled"
				fullWidth={true}
				onChange = {props.onChange}
				value={props.value}
				error={props.error!==""}
				helperText={props.error}
				placeholder={placeholder}
				rows={rows}
				required={required}
				multiline={rows>1}
				InputLabelProps = {inputLabelProps}
				InputProps = {inputProps}
				type={type}
				disabled={disabled}
			/>
			{maxLength>0 &&
			<Box className={classCounter}>{rest}</Box>
			}
		</FormControl>
	);
}

export default InputField;