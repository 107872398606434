import {useState,useEffect} from 'react';
import {googleAuth,auth} from './firebase';
import {User, signInWithPopup, sendSignInLinkToEmail, signInWithEmailAndPassword, isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth";
import {createContainer} from "unstated-next";

//import {Inc} from './Inc';
//import Ajax from './Ajax';

const newMailSettings = {
    url: process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const updateMailSettings = {
    url: process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const useUserState = ()=> {
	const [user, setUser] = useState<User | null>(null);
	const [ready, setReady] = useState(false);
	const check = async (u:any)=>{
		//if( u && u.uid ){
		//	const ajax = new Ajax();
		//	const res = await ajax.getProfile(u.uid);
		//	if( !res.err ){
		//		setProfile(res.profile);
		//		setPayment(res.payment);
		//	} else {
		//		setProfile(Inc.defaultProfile());
		//		setPayment(Inc.defaultPayment());
		//	}
		//} else {
		//	setProfile(Inc.defaultProfile());
		//	setPayment(Inc.defaultPayment());
		//}
        setReady(true);
	}

	const signIn = async (method:"twitter"|"google"|"yahoo"|"email",funcSuccess?:any,funcFailure?:any) => {
        //console.log("user",user);
		try {
			if( method==="google" ){
				await signInWithPopup(auth,googleAuth) && funcSuccess && funcSuccess();
			}
			if( method==="email" ){
				await signInWithEmail(funcSuccess,funcFailure);
			}
		} catch (error) {
			//console.log(error);
			//setUser(null);
			funcFailure && funcFailure();
		}
	}

	const signOut = async () => {
        //console.log("user",user);
		//setProfile(Inc.defaultProfile());
		//setPayment(Inc.defaultPayment());
		await auth.signOut();
	}

	const sendEmail = async (email:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			await sendSignInLinkToEmail(auth, email, newMailSettings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				//console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const sendUpdateEmail = async (email:string,funcSuccess?:any,funcFailure?:any) => {
		let settings = updateMailSettings;
		//settings.url = settings.url + "/" + user?.uid;
		try {
			sendSignInLinkToEmail(auth, email, settings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				//console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const signInWithPassword = async (email:string,password:string) => {
		await signInWithEmailAndPassword(auth, email, password)
	}

	const signInWithEmail = async (funcSuccess:any,funcFailure?:any)=>{
		if (isSignInWithEmailLink(auth, window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');
			if (!email) {
				funcFailure && funcFailure();
			} else {
				await signInWithEmailLink(auth, email)
				.then(function() {
					window.localStorage.removeItem('emailForSignIn');
					funcSuccess && funcSuccess();
				})
				.catch(function(error) {
					console.log(error);
					funcFailure && funcFailure();
				});
			}
		}
	}

	//const updateEmail = async (email:string,funcSuccess?:any,funcFailure?:any)=>{
	//	const user = auth.currentUser;
	//	try {
	//		if( user ){
	//			await user.updateEmail(email);
	//			await user.getIdToken(true);
	//			await user.sendEmailVerification(updateMailSettings);
	//			funcSuccess && funcSuccess();
	//		} else {
	//			funcFailure && funcFailure();	
	//		}
	//	} catch (e) {
	//		//console.log("error",e);
	//		funcFailure && funcFailure();			
	//	}
	//}

	useEffect(()=>{
		auth.onAuthStateChanged(u => setUser(u));
		auth.onIdTokenChanged(u=>check(u));
	},[])

	return { user, ready, signIn, signOut, sendEmail, sendUpdateEmail, signInWithPassword }
}
export const UserContainer = createContainer(useUserState);
