import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface NotFoundProps {

}

const NotFound = (props:NotFoundProps)=> {
	return (
		<Container maxWidth="lg">
			<Box style={{textAlign:'center',marginTop:'5em'}}>
				<PriorityHighIcon color="warning" style={{fontSize:'400%',marginBottom:'1em'}}/>
				<Box style={{color:'#666666'}}>
					ページが見つかりませんでした<br/>
				</Box>
			</Box>
		</Container>
	);
}

export default NotFound;
