import React, { useState,useEffect } from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import {db} from './firebase';
import {addDoc,getDoc,setDoc,doc,collection} from 'firebase/firestore';
import {UserContainer} from './UserContainer';
import FileInput from './FileInput';
import ConfigData from './ConfigData';
import ConfigTitle from './ConfigTitle';
import ConfigOutro from './ConfigOutro';
import ReportQuestion from './ReportQuestion';
import ReportIntro from './ReportIntro';
import ReportOutro from './ReportOutro';
import Toast from './Toast';
import {Inc,ChartQuestion,ChartData,CsvFileData} from './Inc';

interface EditProps {

}

const Edit = (props:EditProps)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		data:Inc.defaultChartData(),
		rare:"",
		editIndex: 0,
		isConfigData: false,
		isConfigTitle: false,
		isConfigOutro: false,
		ver:0,
	});
	const [toast,setToast] = useState("");
	const navigate = useNavigate();
	const params = useParams();

	const create = (result:CsvFileData)=>{
		if(!userState.user){
			return;
		}

		const data = result.data;
		const rows = data.length;
		let list = [] as ChartQuestion[];
		
		for( let i=1; i<data[0].length; i++ ){
			let answers: { [key: string]: number } = {};
			let respondents = 0;
			if( states.data.datas[i] && states.data.datas[i].labels.length>0 ){
				for( let j=0; j<states.data.datas[i].labels.length; j++ ){
					answers[ states.data.datas[i].labels[j] ] = 0;
				}
			}
			for( let j=1; j<rows; j++ ){
				if( data[j][i]!=='' ){
					respondents++;
				}
				let keys = data[j][i].split(";");
				for( let k=0; k<keys.length; k++ ){
					if( keys[k] in answers ){
						answers[ keys[k] ]++;
					} else {
						answers[ keys[k] ]=1;
					}
				}
			}

			var ChartQuestion = Inc.defaultChartQuestion();
			var ChartAnswers = Inc.defaultChartAnswers();
			for( const key in answers ){
				ChartQuestion.labels.push(key);
				ChartAnswers.data.push(answers[key]);
			}
			ChartAnswers.respondents = respondents;

			//ChartAnswers.label = data[0][i];
			ChartQuestion.question = data[0][i];
			ChartQuestion.datasets = [ChartAnswers];

			list.push( ChartQuestion );
		}
		setStates({...states,data:{...states.data,datas:list,uid:userState.user.uid},rare:result.rare});
	}

	const updateData = (data:ChartQuestion)=>{
		const list = states.data.datas.slice(0,states.editIndex).concat(data).concat(states.data.datas.slice(states.editIndex+1));
		setStates({...states,data:{...states.data,datas:list},isConfigData:false});
	}
	const updateTitle = (title:string,intro:string)=>{
		setStates({...states,data:{...states.data,title:title,intro:intro},isConfigTitle:false});
	}
	const updateOutro = (outro:string,writer:string,date:string)=>{
		setStates({...states,data:{...states.data,outro:outro,writer:writer,date:date},isConfigOutro:false});
	}
	const openConfigData = (index:number)=>{
		setStates({...states,editIndex:index,isConfigData:true});
	}
	const closeConfigData = ()=>{
		setStates({...states,isConfigData:false});
	}
	const openConfigTitle = ()=>{
		setStates({...states,isConfigTitle:true});
	}
	const closeConfigTitle = ()=>{
		setStates({...states,isConfigTitle:false});
	}
	const openConfigOutro = ()=>{
		setStates({...states,isConfigOutro:true});
	}
	const closeConfigOutro = ()=>{
		setStates({...states,isConfigOutro:false});
	}

	const save = async ()=>{
		try {
			let id1 = "";
			const now = new Date().getTime();
			if( params.id && params.id!=="new" && params.id!=="" ){
				id1 = params.id;
				const documentRef1 = await doc(db, "chart-datas", id1);
				await setDoc(documentRef1, {...states.data,updated:now});	
			} else {
				const collectionRef1 = await collection(db, "chart-datas");
				const docRef1 = await addDoc(collectionRef1, {...states.data,created:now,updated:now});
				id1 = docRef1.id;
			}

			if( states.rare!=='' ){
				const documentRef2 = await doc(db, "chart-rares", id1 );
				await setDoc(documentRef2, {body:states.rare,uid:states.data.uid});
			}
			
			setToast("保存しました")
			close();
		} catch(e) {
			setToast("保存できませんでした")
			console.error(e);
		}
	}

	const load = async (id:string)=>{
		if(!userState.user){
			return;
		}

		try {
			const docRef = doc(db, "chart-datas", id);
			const docSnap = await getDoc(docRef);
			
			if (docSnap.exists()) {
				const data = docSnap.data() as ChartData;
				if( data.datas.length>0 ){
					data.datas[0].datasets[0].label = '';
				}
				if( data.uid===userState.user.uid ){
					setStates({...states,data:data});
				} else {
					console.log("Wrong uid");
				}
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
			}
		} catch(e) {
			console.error(e);
		}
	}
    const closeToast = ()=>{
        setToast("");
    }

	const close = ()=>{
		navigate('/', {replace:true});
	}

	useEffect(()=>{
		if(userState.ready ){
			if(!userState.user){
				navigate('/', {replace:true});
			} else if( params.id ){
				load( params.id );
			}
		}
	},[userState.ready]);

	return (
		<Container maxWidth="lg">
			{states.data.datas.length===0 ? (
			<Box style={{marginTop:'1em',marginBottom:'1em',display:'flex'}}>
				<FileInput onChange={create}/>
			</Box>
			):(
			<>
			<Box style={{marginTop:'1em',marginBottom:'1em',display:'flex'}}>
				<Box sx={{ flexGrow: 1 }} />
				<Box>
					<Button variant="text" color="primary" startIcon={<CloseIcon/>} onClick={close} style={{marginRight:'1em'}}>やめる</Button>
					<Button variant="contained" color="primary" startIcon={<SaveAltIcon/>} onClick={save} disabled={states.data.title===''}>保存</Button>
				</Box>
			</Box>
			<ReportIntro data={states.data} edit={openConfigTitle}/>
			{states.data.datas.length>0 && states.data.datas.map((data,index1)=>{
				const key = "key"+index1;
				return <ReportQuestion key={key} data={data} edit={()=>openConfigData(index1)} force={false}/>
			})}
			<ReportOutro data={states.data} edit={openConfigOutro}/>
			<ConfigData close={closeConfigData} isOpen={states.isConfigData} data={states.data.datas[states.editIndex]} update={updateData}/>
			<ConfigTitle close={closeConfigTitle} isOpen={states.isConfigTitle} title={states.data.title} intro={states.data.intro} update={updateTitle}/>
			<ConfigOutro close={closeConfigOutro} isOpen={states.isConfigOutro} outro={states.data.outro} writer={states.data.writer} date={states.data.date} update={updateOutro}/>
			<Toast mes={toast} close={closeToast}/>
			</>
			)}
		</Container>
	);
}

export default Edit;
