import React, { useState,useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TooltipMui from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import {db} from './firebase';
import { collection, query, orderBy, where, getDocs } from "firebase/firestore";
import {UserContainer} from './UserContainer';
import ConfigStatus from './ConfigStatus';
import ConfigDelete from './ConfigDelete';
import {ChartListItem} from './Inc';

interface TopProps {

}

const Top = (props:TopProps)=> {
	const userState = UserContainer.useContainer();
	const navigate = useNavigate();
	const [states,setStates] = useState({
		list:[] as ChartListItem[],
		editIndex: 0,
		isStatus: false,
		isDelete: false,
	});

	const loadList = async ()=>{
		if(!userState.user){
			return;
		}
		const datas = [] as ChartListItem[];
		const q = query(collection(db, "chart-datas"), orderBy("created", "desc"), where("uid", "==", userState.user.uid));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			//console.log(doc.id, " => ", doc.data());
			datas.push({
				id:doc.id,
				title: doc.data().title,
				status: doc.data().status,
			});
		});
		setStates({...states,isDelete:false,isStatus:false,list:datas});
	}
	const edit = (id:string)=>{
		navigate('/edit/'+id, {replace:true});
	}
	const updateStatus = async (status:boolean)=>{
		const data = {...states.list[states.editIndex],status:status};
		const list = states.list.slice(0,states.editIndex).concat(data).concat(states.list.slice(states.editIndex+1));
		//setStates({...states,list:list,isStatus:false});
		setStates({...states,list:list});
	}
	const openStatus = (index:number)=>{
		setStates({...states,editIndex:index,isStatus:true});
	}
	const closeStatus = ()=>{
		setStates({...states,isStatus:false});
	}
	const openDelete = ()=>{
		setStates({...states,isDelete:true,isStatus:false});
	}
	const closeDelete = (deleted:boolean)=>{
		if( deleted ){
			//setStates({...states,isDelete:false,isStatus:false});
			loadList();
		} else {
			setStates({...states,isDelete:false,isStatus:true});
		}
	}

	useEffect(()=>{
		if(userState.user){
			loadList();
		}
	},[userState.ready])

	return (
		<Container maxWidth="lg">
			{states.list.length>0 ? (
			<List>
				{states.list.map((item:ChartListItem,index)=>{
					const key = "key"+index;
					let title = "タイトルなし";
					if( item.title ){
						title = item.title;
					}
					const link = process.env.REACT_APP_PUBLIC_URL+item.id;
					return <ListItem
						key={key}
						disablePadding
						secondaryAction={
							<TooltipMui title="公開設定"><IconButton onClick={()=>openStatus(index)}><SettingsIcon/></IconButton></TooltipMui>
						}
					><ListItemButton onClick={()=>edit(item.id)}>
						<ListItemText primary={title} secondary={(
							<>
								{item.status ? (
								<span style={{backgroundColor:'teal',color:'white',padding:'0.1em 0.5em',marginRight:'0.5em'}}>公開中</span>
								):(
								<span style={{backgroundColor:'#EEEEEE',color:'gray',padding:'0.1em 0.5em',marginRight:'0.5em'}}>非公開</span>
								)}
								{link}
							</>
						)}/>
					</ListItemButton></ListItem>;
				})}
			</List>
			):(
			<Box sx={{textAlign:'center',marginTop:'4em'}}>
				<Box style={{marginBottom:'1.5em',color:'teal'}}><FireplaceIcon style={{fontSize:'300%',color:'teal'}}/><br/>Room101</Box>
				<Box sx={{textAlign:{xs:'left',sm:'center'}}}>
					<p>
					アンケートの集計結果をちょっと良い感じにするサービスです
					</p>
					<p>
					GoogleフォームなどのCSVファイルをそのまま読み込むことができます
					</p>
					<p>
					どなたでも利用可能なサービスなんですが、<br/>
					利用規約をまだ書いていないため<span style={{color:'brown'}}>利用登録は休止中</span>です
					</p>
				</Box>
				<Box sx={{textAlign:{xs:'right',sm:'center'},marginTop:'2em'}}>
				2023.3.10 レトロインク<br/>
				</Box>
			</Box>
			)}
			{states.list.length>0 && <ConfigStatus data={states.list[states.editIndex]} update={updateStatus} delete={openDelete} close={closeStatus} isOpen={states.isStatus}/>}
			{states.list.length>0 && <ConfigDelete data={states.list[states.editIndex]} close={closeDelete} isOpen={states.isDelete}/>}
		</Container>
	);
}

export default Top;
