import React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {Theme} from './Theme';
import {ChartData,ReportFilterCondition} from './Inc';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import CancelIcon from '@mui/icons-material/Cancel';

interface ReportFilterProps {
	close():void,
	reset:boolean,
	update(checked:ReportFilterCondition[]):void,
	data:ChartData,
	isOpen:boolean,
}

const ReportFilter:React.FC<ReportFilterProps> = (props)=> {
	const [checked,setChecked] = useState([] as string[]);
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));

	const close = ()=>{
		props.close();
	}

	const update = ()=>{
		const temp: { [key: string]: string[] } = {};
		for( let i=0; i<checked.length; i++ ){
			const sp = checked[i].split("_");
			const q = parseInt(sp[0]);
			const a = parseInt(sp[1]);
			const answer = props.data.datas[q].labels[a];
			if( temp[sp[0]] ){
				temp[sp[0]].push(answer);
			} else {
				temp[sp[0]] = [answer];
			}
		}
		const conds:ReportFilterCondition[] = [];
		for( const key in temp ){
			conds.push({
				q:parseInt(key),
				answers:temp[key],
			});
		}
		props.update(conds);
	}

	const toggleCheck = (key:string)=>{
		const n = checked.indexOf(key)
		if( n>=0 ){
			const newChecked = checked.slice(0,n).concat(checked.slice(n+1));
			setChecked(newChecked);
		} else {
			const newChecked = checked.concat([key]);
			setChecked(newChecked);
		}
	}

	useEffect(()=>{
		if(props.isOpen && props.reset){
			setChecked([]);
		}
	},[props.isOpen,props.reset])

	if(!props.isOpen){
		return (null);
	}

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullScreen={fullScreen}
		>
			<DialogTitle id="yesno-dialog-title">絞り込み</DialogTitle>
			<DialogContent>
				{props.data.datas.length>0 && props.data.datas.map((data,index1)=>{
					const key1 = "data"+index1;
					return (
						<Box key={key1} style={{marginBottom:'4em'}}>
							<Box style={{fontSize:'120%'}}><Stack direction="row" alignItems="center" gap={1}><QuestionAnswerIcon style={{color:'teal'}}/>{data.question}</Stack></Box>					
							<List>
								{data.labels.map((label:string,index2:number)=>{
									const key2 = index1+"_"+index2;
									const labelId = `checkbox-list-label-${index2}`;
									return (
										<ListItemButton key={key2} onClick={()=>toggleCheck(key2)} dense>
											<ListItemIcon>
												<Checkbox
													edge="start"
													color="secondary"
													checked={checked.includes(key2)}
													tabIndex={-1}
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											</ListItemIcon>
											<ListItemText primary={label}/>
										</ListItemButton>
									)
								})}
							</List>
						</Box>
					);
				})}
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
				やめる
				</Button>
				<Button onClick={update} color="primary" variant="contained" autoFocus>
				決定
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ReportFilter;