import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import Title from './Title';
import {ChartData} from './Inc';

interface ReportOutroProps {
	data:ChartData,
	edit?():void,
}

const ReportOutro = (props:ReportOutroProps)=> {
	return 	<Box style={{marginBottom:'8em'}}>
	<Title text="あとがき" variant="h3" icon={<AssessmentIcon color="primary" style={{fontSize:'200%'}}/>}/>
	{props.data.outro && props.data.outro!=='' ? (
	<Box style={{marginBottom:'1em',whiteSpace:'pre-wrap'}}>
		{props.data.outro}
	</Box>
	):(
	<Box style={{marginBottom:'1em',fontSize:'90%',color:'#666666'}}>
		あとがきは未設定です
	</Box>
	)}
	<Divider style={{marginBottom:'1em'}}/>
	<Box style={{fontSize:'90%',color:'#666666'}}>
	{props.data.writer && props.data.writer!=='' &&
	<Box>
		文責：{props.data.writer}
	</Box>
	}
	{props.data.date && props.data.date!=='' &&
	<Box>
		公開日：{props.data.date}
	</Box>
	}
	</Box>
	{props.edit &&
	<Box style={{textAlign:'right'}}>
		<Button onClick={props.edit} startIcon={<SettingsIcon/>}>あとがきとリンクの設定</Button>
	</Box>
	}
</Box>

}

export default ReportOutro;
