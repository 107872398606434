import React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import {Theme} from './Theme';
import InputField from './InputField';
import {Inc,ChartQuestion,Dataset,MaxChartQuestionMemoLength,MaxChartQuestionSupplementLength,ReorderItem} from './Inc';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import CancelIcon from '@mui/icons-material/Cancel';

interface ConfigDataProps {
	close():void,
	update(data:ChartQuestion):void,
	data:ChartQuestion,
	isOpen:boolean,
}

const ConfigData:React.FC<ConfigDataProps> = (props)=> {
	const [data,setData] = useState(Inc.defaultChartQuestion());
	const [errors,setErrors] = useState(Inc.defaultChartQuestionError());
	const [rows, setRows] = useState([] as ReorderItem[]);
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));

	const changeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		if( value==="pie" || value==="doughnut" || value=="yoko" || value=="tate" ){
			setData({...data,type:value});
		}
	};
	const changePercent = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		setData({...data,percent:value});
	};
	const changeValue = (name:keyof ChartQuestion) => (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event) {
			return;
		}
		let value = event.target.value;
		setData({...data,[name]:value})
	};
	const close = ()=>{
		props.close();
	}
	const update = ()=>{
		let old: { [key: string]: number } = {};
		for( let i=0; i<data.labels.length; i++ ){
			old[ data.labels[i] ] = data.datasets[0].data[i];
		}

		let newLabels = [] as string[];
		let newData = [] as number[];
		for( let i=0; i<rows.length; i++ ){
			newLabels.push( rows[i].name );
			newData.push( old[rows[i].name] );
		}

		let datasets = data.datasets[0];
		datasets.data = newData;

		props.update({...data,labels:newLabels,datasets:[datasets]});
	}

	const reorder = (startIndex: number, endIndex: number) => {
		const result = Array.from(rows);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.map((row, index) => (row.order = index));
		return result;
	};

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) {
			return;
		}
		const update = reorder(source.index, destination.index);
		setRows(update);
	};


	useEffect(()=>{
		if(props.isOpen){
			let list = [];
			for( let i=0; i<props.data.labels.length; i++ ){
				list.push({
					name: props.data.labels[i], index: i, order: i,
				} as ReorderItem)
			}
			setData(props.data);
			setRows(list);
		}
	},[props.isOpen])

	if(!props.isOpen){
		return (null);
	}

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullScreen={fullScreen}
		>
			<DialogTitle id="yesno-dialog-title">{props.data.datasets[0].label}</DialogTitle>
			<DialogContent>
				<DialogContentText id="yesno-dialog-description">

				</DialogContentText>
				<Box style={{marginBottom:'1em'}}>
					<FormControl>
						<FormLabel style={{color:'teal'}}>グラフの種類</FormLabel>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={data.type}
							onChange={changeRadio}
							row
						>
						{Inc.chartTypes.map((dataset:Dataset,index:number)=>{
							const key = "key"+index;
							return (<FormControlLabel value={dataset.id} control={<Radio />} label={dataset.label} key={key}/>)
						})}
						</RadioGroup>
					</FormControl>
				</Box>
				<Box style={{marginBottom:'1em'}}>
					<FormControl>
						<FormLabel style={{color:'teal'}}>数値の書式</FormLabel>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={data.percent}
							onChange={changePercent}
							row
						>
						{Inc.chartPercents.map((dataset:Dataset,index:number)=>{
							const key = "key"+index;
							return (<FormControlLabel value={dataset.id} control={<Radio />} label={dataset.label} key={key}/>)
						})}
						</RadioGroup>
					</FormControl>
				</Box>
				<Box style={{marginBottom:'1em'}}>
					<FormLabel style={{color:'teal'}}>答えの順番</FormLabel>
					<DragDropContext onDragEnd={onDragEnd}>
				    	<Droppable droppableId={"dndTableBody"}>
							{(provided) => (
							<List ref={provided.innerRef} {...provided.droppableProps}>
								{rows.map((row, index) => (
								<Draggable
									draggableId={row.name}
									index={index}
									key={row.name}
								>
									{(provided) => (
									<ListItem
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										<ListItemIcon><DragIndicatorIcon/></ListItemIcon>
										<ListItemText primary={row.name}/>
									</ListItem>
									)}
								</Draggable>
								))}
								{provided.placeholder}
							</List>
							)}
						</Droppable>
					</DragDropContext>
				</Box>
				<InputField label="質問の補足" onChange={changeValue("supplement")} value={data.supplement} error={errors.supplement} opt={{maxLength:MaxChartQuestionSupplementLength,rows:4}}/>
				<InputField label="結果の考察" onChange={changeValue("memo")} value={data.memo} error={errors.memo} opt={{maxLength:MaxChartQuestionMemoLength,rows:4}}/>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
				いいえ
				</Button>
				<Button onClick={update} color="primary" variant="contained" autoFocus>
				はい
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfigData;