import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {deleteDoc,doc} from 'firebase/firestore';
import {db} from './firebase';
import {UserContainer} from './UserContainer';
import {Theme} from './Theme';
import {ChartListItem} from './Inc';

interface ConfigDeleteProps {
	close(deleted:boolean):void,
	data:ChartListItem,
	isOpen:boolean,
}

const ConfigDelete:React.FC<ConfigDeleteProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));

	const close = ()=>{
		props.close(false);
	}
	const deleteCharaData = async ()=>{
		if(!userState.user){
			return;
		}
		try {
			await deleteDoc(doc(db, "chart-datas", props.data.id));
			await deleteDoc(doc(db, "chart-rares", props.data.id));
		} catch(e) {
			console.error(e);
		}
		props.close(true);
	}

	if(!props.isOpen){
		return (null);
	}

	const url = process.env.REACT_APP_PUBLIC_URL+props.data.id;


	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullScreen={fullScreen}
		>
			<DialogTitle id="yesno-dialog-title">
				<Stack direction="row" alignItems="center" gap={1}>
					<AssessmentIcon color="primary" style={{fontSize:'200%'}}/>
					<Box style={{color:'teal'}}>{props.data.title}</Box>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
				このアンケート結果を削除してよろしいですか？
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="inherit" onClick={close}>いいえ</Button>
				<Button variant="outlined" color="warning" onClick={deleteCharaData}>はい</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfigDelete;