import React,{useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import {Theme} from './Theme';
import {UserContainer} from './UserContainer';
import Header from	'./Header';
import Top from './Top';
import Edit from './Edit';
import Report from './Report';
import MailNew from './MailNew';
import NotFound from './NotFound';

function App() {
	const [states,setStates] = useState({
		isLoading: true,
		verNewer: "",
		onService: true,
	});
	//const navigate = useNavigate();
	const basename = process.env.REACT_APP_BASENAME;

	const handleOldClose = ()=>{
		//setStates({...states,isOld:false});
	}

	return (
		<UserContainer.Provider>
			<BrowserRouter basename={basename}>
				<ThemeProvider theme={Theme}>
					<Box>
						<Header/>
						<Routes>
							<Route path="/" element={<Top/>}/>
							<Route path="/edit/:id" element={<Edit/>}/>
							<Route path='/mail/new' element={<MailNew/>}/>
							<Route path="/rp/:id" element={<Report/>}/>
							<Route path="/notfound" element={<NotFound/>}/>
						</Routes>
					</Box>
				</ThemeProvider>
			</BrowserRouter>
		</UserContainer.Provider>
	);
}

export default App;
