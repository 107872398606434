import React, { ChangeEventHandler } from 'react';
import { parse } from 'papaparse';
import { Encoding,detect,convert } from 'encoding-japanese';
import { CsvFileData } from './Inc';

interface FileInputProps {
	onChange(result:CsvFileData):void,
}

const FileInput = (props:FileInputProps)=> {
	const handleFiles: ChangeEventHandler<HTMLInputElement> = async (event) => {
		const files = event.currentTarget.files;
		//resetSelection();
		if (!files || files?.length === 0) return;
		const file = files[0];

		const result= await fileParser(file) as CsvFileData;
		props.onChange(result);
		event.target.value = "";
		
	}

	const fileParser = (file: File) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
			  const codes = new Uint8Array(e?.target?.result as ArrayBuffer);
			  const encoding = detect(codes) as Encoding;
			  const unicodeString = convert(codes, {
				to: 'UNICODE',
				from: encoding,
				type: 'string',
			  });
			  parse(unicodeString, {
				complete: (results) => {
				  resolve({data:results?.data,rare:unicodeString});
				},
				error: () => {
				  reject(new Error('csv parse err'));
				},
			  });
			};
			reader.readAsArrayBuffer(file);
		});
	};	

	return (
		<input type="file" onChange={handleFiles}/>
	);
}

export default FileInput;
