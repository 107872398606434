import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {UserContainer} from './UserContainer';
import Login from './Login';

interface HeaderProps {

}

const Header = (props:HeaderProps)=> {
	const userState = UserContainer.useContainer();
	const [isLogin,setIsLogin] = useState(false);
	const navigate = useNavigate();

	const openLogin = ()=>{
		setIsLogin(true);
	}
	const closeLogin = ()=>{
		setIsLogin(false);
	}
	const add = ()=>{
		navigate('/edit/new',{replace:true});
	}
	const top = ()=>{
		navigate('/',{replace:true});
	}

	return (
		<AppBar position="static" style={{backgroundColor:'white',boxShadow:'none',borderBottom:'1px solid teal'}}>
			<Toolbar variant="dense">
				<IconButton edge="start" color="primary" aria-label="menu" sx={{ mr: 2 }} onClick={top}>
					<FireplaceIcon/>
				</IconButton>
				<Typography variant="h6" color="primary" component="div">
					Room101
				</Typography>
				<Box sx={{ flexGrow: 1 }} />
				<Box>
					{userState.user && <IconButton color="primary" onClick={add}><AddCircleOutlineIcon style={{fontSize:'140%'}}/></IconButton>}
					<IconButton color="primary" onClick={openLogin}><AccountCircleIcon style={{fontSize:'140%'}}/></IconButton>
				</Box>
			</Toolbar>
			<Login isOpen={isLogin} close={closeLogin}/>
		</AppBar>
	);
}

export default Header;
