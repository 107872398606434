export const MaxChartTitleLength = 100;
export const MaxChartIntroLength = 500;
export const MaxChartOutroLength = 500;
export const MaxChartWriterLength = 50;
export const MaxChartQuestionMemoLength = 500;
export const MaxChartQuestionSupplementLength = 500;

export interface Dataset {
    id: string,
    label: string,    
}

export interface DataErrorset {
    id: string,
    label: string,
	error: boolean,
}

export interface ChartData {
	datas: ChartQuestion[],
	title: string,
	intro: string,
	outro: string,
	writer: string,
	date: string,
	created: string,
	updated: string,
	uid: string,
	status: boolean,
}
export interface ChartListItem {
	id: string,
	title: string,
	status: boolean,
}

export interface ChartQuestion {
	question: string,
	labels: string[],
	datasets: ChartAnswers[],
	memo: string,
	supplement: string,
	percent: string,
	type: "pie" | "doughnut" | "yoko" | "tate",
}

export interface CsvFileData {
	data: any,
	rare: string,
}

export interface ChartAnswers {
	label: string,
	data: number[],
	respondents: number,
	backgroundColor: string[],
	borderColor: string[],
	borderWidth: 1,
}

export interface ChartDataError {
	title: string,
	intro: string,
	outro: string,
	writer: string,
	date: string,
}

export interface ChartQuestionError {
	labels: string,
	datasets: string,
	memo: string,
	supplement: string,
	order: string,
}

export interface ReorderItem {
	name: string,
	index: number,
	order: number,
}

export interface ReportFilterCondition {
	q: number,
	answers: string[],
}

export class Inc {
    static readonly chartTypes = [
        {
            id:"pie",
            label:"円グラフ",
        },
        {
            id:"doughnut",
            label:"ドーナツグラフ",
        },
        {
            id:"yoko",
            label:"棒グラフ（横）",
        },
        {
            id:"tate",
            label:"棒グラフ（縦）",
        },
    ];	

    static readonly chartPercents = [
        {
            id:"1",
            label:"件数＋パーセント",
        },
        {
            id:"2",
            label:"件数のみ",
        },
    ];	

	static readonly defaultChartQuestion = ()=>{
        let data:ChartQuestion = {
			question: "",
            labels: [],
			datasets: [],
			memo: "",
			supplement: "",
			type: "doughnut",
			percent: "1",
		}
        return data;
    };

	static readonly defaultChartAnswers = ()=>{
        let data:ChartAnswers = {
            label: "",
            data: [] as number[],
			respondents: 0,
			backgroundColor: [
				'rgba(128, 128, 255, 0.8)',
				'rgba(255, 128, 128, 0.8)',
				'rgba(128, 255, 128, 0.8)',
				'rgba(128, 255, 255, 0.8)',
				'rgba(255, 255, 0, 0.8)',
				'rgba(255, 0, 255, 0.8)',
				'rgba(0, 128, 0, 0.8)',
				'rgba(128, 0, 128, 0.8)',
				'rgba(0, 0, 128, 0.8)',
				'rgba(0, 128, 128, 0.8)',
				'rgba(128, 0, 0, 0.8)',
				'rgba(0, 0, 255, 0.8)',
				'rgba(255, 0, 0, 0.8)',
				'rgba(0, 255, 0, 0.8)',
				'rgba(0, 255, 255, 0.8)',
				'rgba(128, 128, 0, 0.8)',
			],
			borderColor: [
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
				'rgba(255, 255, 255, 1)',
			],
			borderWidth: 1,
		}
        return data;
    };

	static readonly defaultChartDataError = ()=>{
        let data:ChartDataError = {
			title: "",
			intro: "",
			outro: "",
			writer: "",
			date: "",
		}
        return data;
    };

	static readonly defaultChartQuestionError = ()=>{
        let data:ChartQuestionError = {
            labels: "",
			datasets: "",
			memo: "",
			supplement: "",
			order: "",
		}
        return data;
    };

	static readonly defaultChartData = ()=>{
        let data:ChartData = {
			datas: [] as ChartQuestion[],
			intro: "",
			title: "",
			outro: "",
			writer: "",
			date: "",
			created: "",
			updated: "",
			uid: "",
			status: true,
		}
        return data;
    };

}