import React, { useState,useEffect } from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import Container from '@mui/material/Container';
import {db} from './firebase';
import {getDoc,doc} from 'firebase/firestore';
import ReportQuestion from './ReportQuestion';
import ReportIntro from './ReportIntro';
import ReportOutro from './ReportOutro';
import ReportMenu from './ReportMenu';
import ReportFilter from './ReportFilter';
import Loading from './Loading';
import {Inc,ChartData,ReportFilterCondition} from './Inc';
import { parse } from 'papaparse';

interface ReportProps {

}

const Report = (props:ReportProps)=> {
	const [states,setStates] = useState({
		data:Inc.defaultChartData(),
		rare:null as null | any,
		isLoading: true,
		isFilter: false,
		filtered: false,
		force:false,
	});
	const navigate = useNavigate();
	const params = useParams();

	const load = async (id:string)=>{
		try {
			const docRef = doc(db, "chart-datas", id);
			const docSnap = await getDoc(docRef);
			
			if (docSnap.exists()) {
				const data = docSnap.data() as ChartData;
				if( data.datas.length>0 ){
					data.datas[0].datasets[0].label = '';
				}
				if( data.status===true ){
					setStates({...states,data:data,isLoading:false});
				} else {
					console.log("Wrong uid");
					notfound();
				}
			} else {
				// doc.data() will be undefined in this case
				console.log("No such document!");
				notfound();
			}
		} catch(e) {
			console.error(e);
		}
	}

	const loadRare = async (id:string)=>{
		if( states.rare ){
			console.log("states Rare");
			return states.rare;
		}

		try {
			const docRef = doc(db, "chart-rares", id);
			const docSnap = await getDoc(docRef);
			
			if (docSnap.exists()) {
				console.log("load Rare");
				const data = docSnap.data();
				const result = parse(data.body);
				return result.data;
			} else {
				console.log("No such document!");
			}
		} catch(e) {
			console.error(e);
		}
	}

	const reset = ()=>{
		update([]);
	}

	const update = async (checked:ReportFilterCondition[])=>{
		if( !params.id ){
			return;
		}
		const rare = await loadRare(params.id);

		//条件に合った行だけ残す
		const list = [] as any[];
		for( let i=1; i<rare.length; i++ ){
			let check = true;
			for( let j=0; j<checked.length; j++ ){
				const q = checked[j].q;
				let check_q = false;
				if( rare[i][q+1] && rare[i][q+1]!=='' ){
					let answers = rare[i][q+1].split(";");
					for( let a=0; a<checked[j].answers.length; a++ ){
						if( answers.includes(checked[j].answers[a]) ){
							check_q = true;
						}
					}
				}
				if( !check_q ){
					check = false;
				}
			}
			if( check ){
				list.push( rare[i] );
			}
		}

		const rows = list.length;
		const data = {...states.data};

		//あらためて回答数を集計する
		for( let i=1; i<rare[0].length; i++ ){
			let answers: { [key: string]: number } = {};
			let respondents = 0;
			if( states.data.datas[i-1] && states.data.datas[i-1].labels.length>0 ){
				for( let j=0; j<states.data.datas[i-1].labels.length; j++ ){
					answers[ states.data.datas[i-1].labels[j] ] = 0;
				}
			}
			for( let j=0; j<rows; j++ ){
				let keys = list[j][i].split(";");
				for( let k=0; k<keys.length; k++ ){
					if( keys[k] in answers ){
						answers[ keys[k] ]++;
					} else {
						answers[ keys[k] ]=1;
					}
				}
				if( list[j][i]!=='' ) {
					respondents++;
				}
			}

			let answerData = [] as number[];
			
			for( const key in data.datas[i-1].labels ){
				if( answers[data.datas[i-1].labels[key]] ){
					answerData.push(answers[data.datas[i-1].labels[key]]);
				} else {
					answerData.push(0);
					//data.datas[i-1].labels = ['test'];
				}
			}

			data.datas[i-1].datasets[0].data = answerData;
			data.datas[i-1].datasets[0].respondents = respondents;
		}
		//console.log(data);

		setStates({...states,data:data,rare:rare,isFilter:false,force:true,filtered:checked.length>0});
	}

	const openFilter = async ()=>{
		setStates({...states,isFilter:true,force:false});
	}
	const closeFilter = ()=>{
		setStates({...states,isFilter:false});
	}

	const notfound = ()=>{
		navigate('/notfound', {replace:true});
	}

	useEffect(()=>{
		if( params.id ){
			load( params.id );
		}
	},[]);

	if( states.isLoading ){
		return <Loading isOpen={true}/>
	}

	return (
		<Container maxWidth="lg">
			<ReportIntro data={states.data}/>
			{states.data.datas.length>0 && states.data.datas.map((data,index)=>{
				const key = "key"+index;
				return <ReportQuestion key={key} data={data} force={states.force}/>
			})}
			<ReportOutro data={states.data}/>
			<ReportFilter data={states.data} isOpen={states.isFilter} update={update} reset={!states.filtered} close={closeFilter}/>
			<ReportMenu reset={reset} filter={openFilter}/>
		</Container>
	);
}

export default Report;
