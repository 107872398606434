import React,{useState,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import GitHubIcon from '@mui/icons-material/GitHub';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from './Theme';
import Title from './Title';
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Loading from './Loading';
import Toast from './Toast';
import useStyles from './Styles';
import {UserContainer} from './UserContainer';

interface LoginProps {
	isOpen: boolean,
	close():void,
}

const Login = (props:LoginProps)=> {
    const [states,setStates] = useState({
        email:"",
        isSend:true,
        isLoading:false,
    });
	const classes = useStyles();
    const [toast,setToast] = useState("");
	const navigate = useNavigate();
    const userState = UserContainer.useContainer();
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));
    const signInWithGoogle = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "google",()=>{
			setStates({...states,isLoading:false});
			navigate('/', {replace:true});
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithTwitter = ()=>{
        setStates({...states,isLoading:true});
        userState.signIn( "twitter",()=>{
			setStates({...states,isLoading:false});
			navigate('/', {replace:true});
        },()=>{
			setStates({...states,isLoading:false});
            setToast("ログインできませんでした");
		});
    }
    const signInWithEmail = ()=>{
        setStates({...states,isLoading:true});
        const email = states.email;
        userState.sendEmail(email,()=>{
            setStates({...states,isLoading:false,isSend:true});
        },()=>{
			setStates({...states,isLoading:false});
            setToast("メールの送信に失敗しました");
        });      
    }

    const validateEmail = ()=>{
        let error = "";
        if( states.email==="" ){
            error = "メールアドレスを入力してください";
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(states.email)){
            error = "メールアドレスが正しくありません";
        }
        if( error!=="" ){
            setToast(error);
        } else {
            signInWithEmail();
        }
    }
    const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStates({...states,"email":event.target.value});
    }
    const logout = ()=>{
        userState.signOut();
		navigate('/', {replace:true});
    }	
    const closeToast = ()=>{
        setToast("");
    }
	const close = ()=>{
		closeToast();
		navigate('/', {replace:true});
	}
  
	let provider = "メール";
	if( userState.user ){
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
		}
	}
	
	useEffect( ()=>{
		setStates({email:"",isSend:false,isLoading:false});
	},[]);

	if( !userState.ready ){
        return (null);
    }

	let title = 'アカウント';
	if(!userState.user){
		title = 'ログイン';
	}

	const disabled = true;

    return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullScreen={fullScreen}
		>
			<DialogTitle id="yesno-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="yesno-dialog-description">

				</DialogContentText>

            {!states.isSend ? (
				<React.Fragment>
                {userState.user &&
				<React.Fragment>
					<Box style={{marginBottom:'4em'}}>
						<Title variant="h5" icon={<AccountCircleIcon/>} text="ログイン中のアカウント"/>
						<ul className={classes.accountData}>
						{userState.user?.displayName &&
							<li>{userState.user?.displayName}</li>
						}
							<li>{provider} でログイン</li>
						</ul>
						{userState.user?.email &&
						<React.Fragment>
							<Title variant="h5" icon={<MailIcon/>} text="メールアドレス"/>
							<ul className={classes.accountData}>
								<li>
									{userState.user?.email}
								</li>
							</ul>
						</React.Fragment>
						}													
					</Box>
					<FormControl fullWidth={true}>
						<Button variant="contained" color="primary" onClick={logout} startIcon={<LogoutIcon/>}>ログアウト</Button>
					</FormControl>
				</React.Fragment>
                }
                {!userState.user &&
                <React.Fragment>
					<Box style={{marginBottom:'1em'}}>
						SNSのアカウントまたはメールでログインしてご利用ください
					</Box>
					<Box style={{padding:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
						<Box style={{color:'brown'}}>・利用規約をまだ書いていないため利用登録は休止中です。</Box>
						・現在のところSNSは<b>Google</b>のみ利用可能です。<br/>
						・メールの場合は <b>noreply@retroink-fire.firebaseapp.com</b> から怪しいメールが届きますので、メールに書かれたリンクにアクセスしてください。<br/>
					</Box>
					<Grid container spacing={1} style={{marginTop:'2em',marginBottom:"0.5em"}}>
						<Grid item xs={12} sm={6} md={4}>
							<FormControl fullWidth={true}>
								<Button variant="contained" onClick={signInWithGoogle} disabled={disabled}>
									<GoogleIcon className={classes.pageTitleIcon}/> Google
								</Button>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<FormControl fullWidth={true}>
								<Button variant="contained" onClick={signInWithTwitter} disabled={true}>
									<TwitterIcon className={classes.pageTitleIcon}/> Twitter
								</Button>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<FormControl fullWidth={true}>
								<Button variant="contained" onClick={signInWithTwitter} disabled={true}>
									<GitHubIcon className={classes.pageTitleIcon}/> GitHub
								</Button>
							</FormControl>
						</Grid>
					</Grid>

                    <Box className={classes.mailLoginInput}>
                        <FormControl fullWidth={true}>
                            <TextField
                                id="email"
                                value={states.email}
                                onChange={changeEmail}
                                margin="dense"
                                placeholder="メールアドレス"
								disabled={disabled}
                                //variant="outlined"
                            />
                        </FormControl>
                        
                        <FormControl fullWidth={true} style={{marginBottom:"1em"}}>
                            <Button variant="contained" onClick={validateEmail} disabled={disabled}>
                                <MailIcon className={classes.pageTitleIcon}/> メールでログイン
                            </Button>
                        </FormControl>
                    </Box>
				</React.Fragment>
                }
				</React.Fragment>
                
            ):(
				<Box>
					{states.email}にメールを送信いたしましたのでご確認ください。<br/>
					メールに書かれたURLは1回限り有効です。<br/>
				</Box>
            )}

        	    <Toast mes={toast} close={closeToast}/>
    	        <Loading isOpen={states.isLoading}/>
	        </DialogContent>
			<DialogActions>
				<Button variant="text" startIcon={<CloseIcon/>} onClick={props.close}>閉じる</Button>
			</DialogActions>
		</Dialog>
    );
}

export default Login;
