import React,{useState,useEffect} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SettingsIcon from '@mui/icons-material/Settings';
import SquareIcon from '@mui/icons-material/Square';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, ArcElement, PointElement, LineElement, RadialLinearScale, Tooltip, Legend, Title } from 'chart.js'
import DataLabels from 'chartjs-plugin-datalabels';
import {Theme} from './Theme';
import {ChartQuestion} from './Inc';

interface ReportQuestionProps {
	data:ChartQuestion,
	force:boolean,
	edit?():void,
}

Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, PointElement, LineElement, RadialLinearScale, Tooltip, Legend, Title, DataLabels);

const ReportQuestion = (props:ReportQuestionProps)=> {
	const tinyScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	const all = props.data.datasets[0].respondents;
	const height = props.data.datasets[0].data.length * 50;
	const width = props.data.datasets[0].data.length * 150;
	const optionsEn = {
		indexAxis: 'x' as const,
		responsive: true,
		plugins: {
			legend: {
				display: false,
				position: 'right'  as const,
			},
			datalabels: {
				display: true,
				anchor: 'center',
				align: 'center',
				formatter(value:number) {
					if (value === null || value === 0) {
					  return '';
					}
					if( props.data.percent==="1" ){
						return `${(value/all*100).toFixed(1)}%`
					} else {
						return `${value}`
					}
				},				
			} as const,
		},
	};
	const optionsYoko = {
		indexAxis: 'y' as const,
		responsive: true,
		layout: {
			padding: {
				right: tinyScreen ? 20 : 40,
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			datalabels: {
				display: true,
				anchor: 'end',
				align: tinyScreen ? 'center' : 'right',
				formatter(value:number) {
					if (value === null || value === 0) {
					  return '';
					}
					if( props.data.percent==="1" ){
						return `${(value/all*100).toFixed(1)}%`
					} else {
						return `${value}`
					}
				},				
			} as const,
		},
	};
	const optionsTate = {
		indexAxis: 'x' as const,
		responsive: true,
		layout: {
			padding: {
				top: 20,
			},
		},
		plugins: {
			legend: {
				display: false,
				position: 'center' as const,
			},
			datalabels: {
				display: true,
				anchor: 'end',
				align: 'center',
				formatter(value:number) {
					if (value === null || value === 0) {
					  return '';
					}
					if( props.data.percent==="1" ){
						return `${(value/all*100).toFixed(1)}%\n`
					} else {
						return `${value}\n`
					}
				},				
			} as const,
		},
	};

	return <Box style={{marginBottom:'8em',position:'relative'}}>
		<Box style={{fontSize:'120%',marginBottom:'0.5em'}}><Stack direction="row" alignItems="center" gap={1}><QuestionAnswerIcon style={{color:'teal'}}/>{props.data.question}</Stack></Box>
		{props.data.supplement!=='' &&
		<Box style={{fontSize:'90%',color:'#666666',marginBottom:'0.1em',whiteSpace:'pre-wrap'}}>{props.data.supplement}</Box>
		}
		<Box style={{fontSize:'80%',color:'#666666'}}>{all}件の回答</Box>
		<Box style={{marginTop:'1em'}}>
		{props.data.type==="doughnut" && 
		<Box>
			<Box sx={{display:{xs:'block',sm:'table-cell'},position:'relative'}}>
				<Doughnut data={props.data} options={optionsEn} redraw={props.force}/>
				<Box style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',color:'#333333',fontSize:'90%'}}>{all}件の回答</Box>
			</Box>
			<Box sx={{
				display:{xs:'block',sm:'table-cell'},
				verticalAlign:{xs:'top',sm:'middle'},
				textAlign:{xs:'center',sm:'left'},
				paddingTop:{xs:'1em',sm:'0'},
				paddingLeft:{xs:'0',sm:'1em'},
			}}>
				{props.data.labels.map((label:string,index2:number)=>{
					const key = "legend"+index2;
					return <Stack direction="row" alignItems="center" gap={1} key={key}>
						<SquareIcon style={{color:props.data.datasets[0].backgroundColor[index2]}}/>
						<span style={{fontSize:'80%',color:'#333333',overflow:'hidden',whiteSpace:'nowrap'}}>{label}　{props.data.datasets[0].data[index2]} 
						{props.data.percent==="1" &&
							<>
							({(props.data.datasets[0].data[index2]/all*100).toFixed(1)}%)
							</>
						}
						</span>
					</Stack>
				})}
			</Box>
		</Box>
		}
		{props.data.type==="pie" && 
		<Box>
			<Box sx={{display:{xs:'block',sm:'table-cell'},position:'relative'}}>
				<Pie data={props.data} options={optionsEn} redraw={props.force}/>
			</Box>
			<Box sx={{
				display:{xs:'block',sm:'table-cell'},
				verticalAlign:{xs:'top',sm:'middle'},
				textAlign:{xs:'center',sm:'left'},
				paddingTop:{xs:'1em',sm:'0'},
				paddingLeft:{xs:'0',sm:'1em'},
			}}>
				{props.data.labels.map((label:string,index2:number)=>{
					const key = "legend"+index2;
					return <Stack direction="row" alignItems="center" gap={1} key={key}>
						<SquareIcon style={{color:props.data.datasets[0].backgroundColor[index2]}}/>
						<span style={{fontSize:'80%',color:'#333333',overflow:'hidden',whiteSpace:'nowrap'}}>{label}　{props.data.datasets[0].data[index2]} 
						{props.data.percent==="1" &&
							<>
							({(props.data.datasets[0].data[index2]/all*100).toFixed(1)}%)
							</>
						}
						</span>
					</Stack>
				})}
			</Box>
		</Box>
		}
		{props.data.type==="yoko" && <Box style={{height:height+'px'}}><Bar data={props.data} options={optionsYoko} redraw={props.force}/></Box>}
		{props.data.type==="tate" && <Box style={{width:width+'px',maxWidth:'100%'}}><Bar data={props.data} options={optionsTate} redraw={props.force}/></Box>}
		</Box>
		{props.data.memo!=='' &&
		<Box style={{padding:'1em',borderRadius:'0.5em',backgroundColor:'#F0F0F0',fontSize:'90%',whiteSpace:'pre-wrap'}}>{props.data.memo}</Box>
		}
		{props.edit &&
		<Box style={{textAlign:'right'}}>
			<Button onClick={props.edit} startIcon={<SettingsIcon/>}>この設問の設定</Button>
		</Box>
		}
	</Box>
}

export default ReportQuestion;
