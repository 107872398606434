import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import Title from './Title';
import {ChartData} from './Inc';

interface ReportIntroProps {
	data:ChartData,
	edit?():void,
}

const ReportIntro = (props:ReportIntroProps)=> {
	return <Box style={{marginBottom:'8em'}}>
		<Title text={props.data.title!=='' ? props.data.title : '保存するにはタイトルが必須です'} variant="h2" icon={<AssessmentIcon color="primary" style={{fontSize:'200%'}}/>}/>
		{props.data.intro && props.data.intro!=='' ? (
		<Box style={{marginBottom:'1em',whiteSpace:'pre-wrap'}}>
			{props.data.intro}
		</Box>
		):(
		<Box style={{marginBottom:'1em',fontSize:'90%',color:'#666666'}}>
			イントロテキストは未設定です
		</Box>
		)}
		{props.edit &&
		<Box style={{textAlign:'right'}}>
			<Button onClick={props.edit} startIcon={<SettingsIcon/>}>タイトルとイントロの設定</Button>
		</Box>
		}
	</Box>
}

export default ReportIntro;
