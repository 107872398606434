//import { makeStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {Theme} from './Theme';

const drawerWidth = 240;
const mainColorPale = '#ECEFE8';
//const Styles = {
const useStyles = makeStyles({
    root: {
        //flexGrow: 1,
        overflow: "hidden",
        [Theme.breakpoints.down('md')]: {
            padding: "0.2em 0",
        },
        [Theme.breakpoints.up('md')]: {
            padding: "1em",
        },
		paddingBottom:'2em!important',
    },
    main: {
        paddingTop: "4em",
        [Theme.breakpoints.up('lg')]: {
        marginLeft: drawerWidth,
        },
    },
    primary: {
        color: `${Theme.palette.primary.main}!important`,
    },
    secondary: {
        color: `${Theme.palette.secondary.main}!important`,
    },
    new: {
        color: `${Theme.palette.warning.main}!important`,
    },
    link: {
        color: Theme.palette.primary.dark,
        textDecoration: "none",
        display:"inline-flex!important",
        verticalAlign:"middle!important",
    },
    linkButton: {
        //color: Theme.palette.primary.dark,
		color: '#333333',
        textDecoration: "none",
    },
    linkText: {
        color: Theme.palette.primary.dark,
        textDecoration: "none",
    },
	helpIndex: {
		color: Theme.palette.primary.main,
		textDecoration: "none",
	},
	helpListPrimary: {
		//color: Theme.palette.primary.main,
		color: 'teal',
	},
	helpListSecondary: {
		color:'#666666!important',
	},
	helpListPrimaryOnly: {
		//color: Theme.palette.primary.main,
		color: '#333333',
	},
	listSubHeader: {
		color: '#666666!important',
	},
    appbar: {
        marginBottom:"0",
        
    },
    toolbar: {
        backgroundColor:"#FFFFFF",
        color:"#333333",
        paddingLeft:"1em!important",
        paddingRight:"1em!important",
    },
    menuButton: {
        padding:"0.5em 0!important",
        marginRight:"0.5em!important",
    },
	//defaultButton: {
	//	borderColor:'#CCCCCC!important',
	//},
    personButton: {
        padding:"0!important",
    },
    person: {
        flexGrow:1,
        textAlign:"right",
    },
    loginName: {
        marginRight:"0.5em",
    },
    topmenu: {
        marginRight:"1em",
        color:"white!important",
    },
    topmenus: {
        marginLeft:"2em",
    },
    drawerTitle: {
        textAlign:"center",
        padding:"1em 0",
    },
    drawerList: {
        width:"240px",
        textAlign:"left",
    },
    loginForm: {
        width:"100%",
        maxWidth:"360px",
        //height:"210px",
        margin:"0em auto",
        marginBottom:"1em",
        padding:"1em",
        boxSizing:"border-box",
        //backgroundColor:"white",
        //boxShadow:"0px 0px 2px 1px #E5E5E5",
    },
    paper: {
        padding:"2em 1em",
        paddingBottom:"2.5em",
        lineHeight:"1.8em",
        fontSize:"90%",
    },
    whiteBelt: {
        padding:"1em",
    },
    loading: {
        position:"fixed",
        top:"0",
        left:"0",
        width:"100%",
        height:"100%",
        backgroundColor:"white",
        opacity:0.7,
        boxSizing:"border-box",
        zIndex:10000,
    },
    spinner: {
        position:"absolute",
        top:"50%",
        left:"50%",
        transform : "translate(-50%,-50%)",
        webkitTransform : "translate(-50%,-50%)",
        msTransform : "translate(-50%,-50%)",
        boxSizing:"border-box",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        paddingTop:"4em",
        backgroundColor:"#F7F7F8!important",
    },
    nested: {
        paddingLeft: "1.5em!important",
        fontSize:"90%!important",
    },
    more: {
        padding:"1em 0",
        textAlign:"right",
    },
    paperTitle: {
        display:"inline-flex!important",
        verticalAlign:"middle!important",
        color: `${Theme.palette.primary.main}!important`,
        marginBottom:"1em!important",
    },
    paperTitleWithoutMargin: {
        display:"inline-flex!important",
        verticalAlign:"middle!important",
        color: `${Theme.palette.primary.main}!important`,
    },
    withIcon: {
        display:"inline-flex!important",
        verticalAlign:"middle!important",
    },
    card: {
        padding:"1em",
    },
    section: {
        marginBottom: "1em",
    },
    hint: {
        fontSize:"90%",
        //color:`${Theme.palette.primary.dark}!important`,
    },
    hintFirst: {
        fontSize:"90%",
        //color:`${Theme.palette.primary.dark}!important`,
		color: '#333333',
		backgroundColor: '#EEEEEE',
		padding:'0.5em 1em',
		marginTop:'1em',
    },
	hintList: {
		margin:'1em 0',
		fontSize:'90%',
		color:'#996666',
	},
    chart: {
        width:"100%",
        maxWidth:"600px",
        margin:"1em auto",
        marginTop:"2em",
    },
    chartCard: {
        padding:"1em",
        textAlign:"center",
        //backgroundColor:`${Theme.palette.secondary.light}!important`,
    },
    chartCardArt: {
        padding:"1em",
        textAlign:"center",
        //backgroundColor:`${Theme.palette.secondary.light}!important`,
    },
    chartCardBook: {
        padding:"1em",
        textAlign:"center",
        //backgroundColor:`${Theme.palette.primary.light}!important`,
    },
    chartDownward: {
        textAlign:"center",
        margin:"1em 0",
    },
    chartDownwardIcon: {
        fontSize:"300%!important",
    },
    important: {
        backgroundColor:`${Theme.palette.warning.main}!important`,
        padding:"0 0.5em",
        borderRadius:"0.5em",
        color:"white",
        marginRight:"0.5em",
    },
    p: {
        marginTop: "0",
        lineHeight: "1.8em",
    },
    formControl: {
        marginBottom:"1em!important",
    },
    formLabel: {
        //color:`${Theme.palette.primary.light}!important`,
		color: '#666666!important',
    },
    formValue: {
		color: '#333333!important',
    },
    radio: {

    },
    radiogroup: {

    },
    textfield: {

    },
    helper: {
        lineHeight:"1.5em!important",
        fontSize:"90%!important",
        color:"#555555!important",
		marginLeft:'0!important',
    },
    dialogRoot: {
        boxSizing:"border-box",
        padding: "1em",
    },
    alert: {
        position:"absolute",
        width:"100%",
        //height:"100%",
        maxWidth:"240px",
        //maxHeight:"620px",
        backgroundColor:"#FFFFFF",
        boxSizing:"border-box",
    },
    alertL: {
        position:"absolute",
        width:"100%",
        //height:"100%",
        maxWidth:"300px",
        //maxHeight:"620px",
        backgroundColor:"#FFFFFF",
        boxSizing:"border-box",
    },
    alertLL: {
        position:"absolute",
        width:"100%",
        //height:"100%",
        maxWidth:"420px",
        //maxHeight:"620px",
        backgroundColor:"#FFFFFF",
        boxSizing:"border-box",
    },
    calendar: {
        position:"absolute",
        width:"100%",
        //height:"100%",
        minWidth:"300px",
        maxWidth:"360px",
        paddingTop:"2em",
        //maxHeight:"620px",
        backgroundColor:"#FFFFFF",
        boxSizing:"border-box",
    },
    modal: {
        position:"absolute",
    },
    modalConfirm: {
        backgroundColor:"white",
        boxSizing:"border-box",
        overflowY:"auto",
        [Theme.breakpoints.down('md')]: {
            padding: "1em",
        },
        [Theme.breakpoints.up('md')]: {
            padding: "2em",
        },
        paddingTop:"3em!important",
    },
    bottomButtons: {
        padding:"2em 0",
		paddingBottom:'4em',
        textAlign:"center",
    },
    confirmGuide: {
        marginBottom:"1.5em",
        paddingBottom:"1.5em",
        //borderBottom:"1px solid silver",
    },
    snackbarMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    currentYear: {
        color: `${Theme.palette.secondary.dark}!important`,
    },
    input: {
        //border:"1px solid silver",
        paddingTop:"0.5em!important",
        //borderRadius:"0.5em",
    },
    select: {
        marginTop:"0.6em!important",
    },
    searchButtons: {
        textAlign:"right",
    },
    checkbox: {
        margin:"0!important",
        paddingBottom:"0.1em!important",
        paddingTop:"0.1em!important",
        paddingRight:"0.1em!important",
    },
    listCheckbox: {
        margin:"0!important",
        padding:"0.1em!important",
        paddingLeft:"0!important",
    },
    checkboxes: {
        paddingTop:"0.5em",
    },
    buttonReset: {
        marginTop:"2em!important",
        marginBottom:"0.5em!important",
    },
    tableCellDense: {
        padding:"0.5em 0.5em!important",
        lineHeight:"1.7em!important",
    },
    tableCellNowrap: {
        whiteSpace: "nowrap",
        padding:"0 0.5em!important",
		fontSize:"90%!important",
		lineHeight:"1.7em!important",
    },
    tableHeadNowrap: {
        whiteSpace: "nowrap",
        padding:"0 0.5em!important",
		fontSize:"90%!important",
		color:'#666666!important',
    },
    itemId: {
        border: "1px solid #669966",
        color: "#669966",
		fontWeight:'bold',
        padding:"0 0.5em",
        borderRadius:"0.5em",
    },
    pageId: {
        border: "1px solid #666666",
        color: "#666666",
        padding:"0.2em 0.5em",
        borderRadius:"0.5em",
    },
    badgeOK: {
        backgroundColor: "#468847",
        color: "white",
        padding:"0em 0.5em",
        borderRadius:"1em",
    },
    badgeNG: {
        backgroundColor: "#468847",
        color: "white",
        padding:"0 0.5em",
        borderRadius:"1em",
    },
    badgeGray: {
        backgroundColor: "gray",
        color: "white",
        padding:"0 0.5em",
        borderRadius:"1em",
    },
    badgeBlue: {
        backgroundColor: "#3a87ad",
        color: "white",
        padding:"0 0.5em",
        borderRadius:"1em",
    },
    badgeOrange: {
        backgroundColor: "#f89406",
        color: "white",
        padding:"0 0.5em",
        borderRadius:"1em",
    },
    badgeWarning: {
        backgroundColor: "red",
        color: "white",
        padding:"0 0.5em",
        borderRadius:"1em",
    },
    badgeBlack: {
        backgroundColor: "black",
        color: "white",
        padding:"0 0.5em",
        borderRadius:"1em",
    },

    subtitle2: {

    },
    img: {
        margin:"0",
        padding:"0",
    },
    imgItemLarge: {
        width:"100%",
        maxWidth:"600px",
    },
    imgItemPhoto: {
        maxWidth:"100%",
    },
    imgList: {
        maxWidth:"40px",
        maxHeight:"40px",
		verticalAlign:'middle',
    },
    imgProfile: {
        maxWidth:"100px",
        maxHeight:"100px",
    },
    imgProfileHeader: {
        maxWidth:"100%",
        maxHeight:"400px",
    },
    imgProfilePhoto: {
        maxWidth:"100%",
    },
    imgSortItem: {
        maxWidth:"80px",
        maxHeight:"80px",
        border:"1px solid silver",
    },
    listItemText: {
        fontSize:"110%!important",
    },
    listItemTextSmall: {
        fontSize:"90%!important",
    },
    hintUl: {
        margin:"1em 0",
        padding:"1em",
        listStyle:"none",
        backgroundColor:"#EEEEEE",
    },
    hintLi: {
        fontSize:"90%",
        color:"#333333",
        padding:"0",
        margin:"0",
        marginBottom:"0.5em",
    },
    guideUl: {
        margin:"1em 0",
        padding:"0em",
        paddingLeft:"1.5em",
        marginTop:"1em",
    },
    guideLi: {
        fontSize:"90%",
        lineHeight:"1.5em",
        color:"#333333",
        padding:"0",
        margin:"0",
        marginBottom:"1em",
    },
    mitei: {
        color:"#f89406",
    },
    funou: {
        color:"#fF3333",
    },
    iconOk: {
        backgroundColor:"DARKGREEN",
        color:"white",
        padding:"0.1em 0.5em",
        borderRadius:"0.5em",
    },
    iconNg: {
        backgroundColor:"RED",
        color:"white",
        padding:"0.1em 0.5em",
        borderRadius:"0.5em",
    },
    favIcon: {
        color:"white",
        padding:"0.1em 0.5em",
        borderRadius:"0.5em",
    },
    favBackColor1: {
        backgroundColor:"#6666FF",
    },
    favBackColor2: {
        backgroundColor:"#FFCC66",
    },
    favBackColor3: {
        backgroundColor:"#66CC66",
    },
    favBackColor4: {
        backgroundColor:"#CC6633",
    },
    favBackColor5: {
        backgroundColor:"#66FFCC",
    },
    favBackColor6: {
        backgroundColor:"#FFCC66",
    },
    favBackColor9: {
        backgroundColor:"#666666",
    },
    favColor1: {
        color:"#6666FF",
    },
    favColor2: {
        color:"#FFCC66",
    },
    favColor3: {
        color:"#66CC66",
    },
    favColor4: {
        color:"#CC6633",
    },
    favColor5: {
        color:"#66FFCC",
    },
    favColor6: {
        color:"#FFCC66",
    },
    favColor9: {
        color:"#666666",
    },
    favTypeList: {
        position:"relative",
        textAlign:"left",
        borderBottom:"1px dashed silver",
        minWidth:"12em",
		padding:'0.5em 0',
    },
    favTypeListCount: {
        position:"absolute",
        top:"0",
        right:"0",
    },
    favTypeListCountMiddle: {
        position:"absolute",
        top:"50%",
        right:"0",
        fontSize:"120%",
        fontWeight:"bold",
		transform : "translate(0%,-50%)",
    },
    favTypeListCountTop: {
        position:"absolute",
        top:"1.25em",
        right:"0",
        fontSize:"100%",
        fontWeight:"bold",
		transform : "translate(0%,-50%)",
    },
	requestListCount: {
		fontSize: '120%!important',
		borderBottom: '1px solid silver',
	},
    policy: {
        maxWidth:"960px",
		margin:"0 auto",
    },
	HelpIndex: {
		color: "#333333",
	},
	selectFilled: {
		borderWidth:"0em",
	},
	itemDetailLabel: {
		fontSize:'110%',
		color:'#666666',
	},
	itemDetailValue: {
		fontSize:'120%',
		color:'#222222',
	},
	rowLabel: {
		color:'#666666!important',
	},
	rowValue: {
		color:'#222222!important',
	},
	formValidator: {
		margin:'1em 0!important',
	},
	stepH3: {
		margin:'0',
		fontWeight:'normal',
		fontSize:'150%',
		marginBottom:'1em',
		//color:#484858;
		color:`${Theme.palette.primary.main}!important`,
	},
	yourMail: {
		color:'#222222',
		whiteSpace:'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width:'100%',
		textAlign:'right',
	},
	dialogActions: {
		marginBottom:'0.5em',
		marginRight:'0.5em',
	},
	infoPrimary: {
		color: '#333333',
	},
	infoSecondary: {
		color: '#888888',
	},
	pageTitleIcon: {
		verticalAlign:'bottom',
		marginRight: '0.25em',
	},
	mailLoginInput: {
		marginTop:'1em',
		border:'1px solid silver',
		padding:'1em',
	},
	accountData: {
		listStyle:'none',
		margin:'0',
		padding:'0',
		marginBottom:'2em',
		width:'100%',
		maxWidth:'280px',
	},
	formInput: {
		backgroundColor:`${mainColorPale}!important`,
	},
	formCounter: {
		position:'absolute',
		bottom:'0.1em',
		right:'0.5em',
		fontSize:'70%',
		color:'#999999',
	},	
	formCounterMinus: {
		color:'red',
	},
	formError: {
		color: 'red',
		fontSize: '80%',
		marginTop: '0.25em',
		marginLeft: '1em',
	},
	formDropzone: {
		borderBottom: '1px solid #999999',
		borderRadius: '0.2em 0.2em 0 0',
		marginTop: '0.5em!important',
		paddingTop: '0.5em',
		backgroundColor:`${mainColorPale}`,
	},
	formDropzoneError: {
		borderColor: `red`,
	},
	formImage: {
		borderBottom: '1px solid #999999',
		borderRadius: '0.2em 0.2em 0 0',
		backgroundColor:`${mainColorPale}`,
	},
	formImageBox: {
		position:'relative',
		borderWidth:'0',
		borderRadius:'0',
		margin:'0',
		padding:"0",
		width:'100%',
	},
	formImageCancelButton: {
		color: '#000000',
		backgroundColor: '#FFFFFF',
		borderRadius: '1em',
	},
	formRequired: {
		backgroundColor:'#996666',
		fontSize:'60%',
		color:'#FFFFFF',
		padding:'0 0.5em',
		borderRadius:'0.25em',
		marginLeft:'0.5em',
		verticalAlign:'middle',
	},
	formExplanation: {
		fontSize:'85%',
		marginTop:'0.5em',
		marginRight:'1em',
		color:'#888888',
	},
	formInner: {
		color: '#666666!important',
		padding: '1em!important',
		top:'0.5em',
	},
	formInnerSmall: {
		color: '#666666!important',
		padding: '1em!important',
		top:'-0.3em',
		left: '0.1em',
		fontSize:'75%!important',
	},
	formImageButton: {
		color: '#FFFFFF!important',
		backgroundColor:'#000000',
		opacity: 0.6,
		//border:'1px solid black',
	},
	formImageButtonSelected: {
		color: '#FFCC99!important',
	},
	formImagePager: {
		width: '100%',
		textAlign: 'center',
	},

});

export default useStyles;