import React,{useState,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyToClipboard from 'react-copy-to-clipboard';
import {getDoc,setDoc,doc} from 'firebase/firestore';
import {db} from './firebase';
import {UserContainer} from './UserContainer';
import Toast from './Toast';
import {Theme} from './Theme';
import {ChartData,ChartListItem} from './Inc';
//import IconButton from '@mui/material/IconButton';
//import Tooltip from '@mui/material/Tooltip';
//import CancelIcon from '@mui/icons-material/Cancel';

interface ConfigStatusProps {
	close():void,
	update(status:boolean):void,
	delete():void,
	data:ChartListItem,
	isOpen:boolean,
}

const ConfigStatus:React.FC<ConfigStatusProps> = (props)=> {
	const userState = UserContainer.useContainer();
	const navigate = useNavigate();
	const [toast,setToast] = useState("");
	const [data,setData] = useState({
		status:true,
	});
	const fullScreen = useMediaQuery(Theme.breakpoints.down('md'));

	const close = ()=>{
		props.close();
	}
	const remove = ()=>{
		props.delete();
	}
	const show = async ()=>{
		await updateStatus(true)
		props.update(true);
	}
	const hide = async ()=>{
		await updateStatus(false)
		props.update(false);
	}
	const updateStatus = async (status:boolean)=>{
		if(!userState.user){
			return;
		}
		try {
			const docRef = doc(db, "chart-datas", props.data.id);
			const docSnap = await getDoc(docRef);
				
			if (docSnap.exists()) {
				const data = docSnap.data() as ChartData;
				if( data.uid===userState.user.uid ){
					await setDoc(docRef, {...data,status:status});						
				} else {
					console.log("Wrong uid");
				}
			} else {
				console.log("No such document!");
			}
		} catch(e) {
			console.error(e);
		}
	}
	const copy = ()=>{
		setToast('URLをコピーしました');
	}
    const closeToast = ()=>{
        setToast("");
    }
	const see = ()=>{
		navigate('/rp/'+props.data.id, {replace:true});
	}
	useEffect(()=>{
		if(props.isOpen){
			setData({status:props.data.status});
		}
	},[props.isOpen])

	if(!props.isOpen){
		return (null);
	}

	const url = process.env.REACT_APP_PUBLIC_URL+props.data.id;


	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby="yesno-dialog-title"
			aria-describedby="yesno-dialog-description"
			fullScreen={fullScreen}
		>
			<DialogTitle id="yesno-dialog-title">
				<Stack direction="row" alignItems="center" gap={1}>
					<AssessmentIcon color="primary" style={{fontSize:'200%'}}/>
					<Box style={{color:'teal'}}>{props.data.title}</Box>
				</Stack>
			</DialogTitle>
			<DialogContent>
				{props.data.status ? (
					<Box style={{padding:'1em',textAlign:'center',fontSize:'120%',color:'teal',border:'1px solid teal'}}>公開中</Box>
				):(
					<Box style={{padding:'1em',textAlign:'center',fontSize:'120%',color:'gray',backgroundColor:'#F5F5F5',border:'1px solid gray'}}>非公開</Box>
				)}
				<Box style={{margin:'1em 0'}}>
					<Box style={{fontSize:'80%'}}>公開URL</Box>
					<Box style={{color:'#333399'}}>{process.env.REACT_APP_PUBLIC_URL}{props.data.id}</Box>
				</Box>
				<Grid container spacing={1}>
					<Grid item xs={6} md={4}><FormControl fullWidth>
						{props.data.status ? (
						<Button size="small" variant="contained" color="inherit" onClick={hide} startIcon={<CloseIcon/>}>非公開にする</Button>
						):(
						<Button size="small" variant="contained" color="primary" onClick={show} startIcon={<CheckIcon/>}>公開する</Button>
						)}
					</FormControl></Grid>
					<Grid item xs={6} md={4}><FormControl fullWidth><CopyToClipboard text={url} onCopy={copy}><Button variant="text" size="small" color="primary" startIcon={<ContentPasteIcon/>}>URLをコピー</Button></CopyToClipboard></FormControl></Grid>
					<Grid item xs={12} md={4}><FormControl fullWidth><Button onClick={see} variant="text" size="small" color="primary" startIcon={<LaunchIcon/>}>ページを開く</Button></FormControl></Grid>
				</Grid>
			</DialogContent>
			<Box style={{display:'flex',padding:'1em'}}>
				<Button variant="text" size="small" color="warning" onClick={remove} startIcon={<DeleteIcon/>}>削除する</Button>
				<Box sx={{ flexGrow: 1 }}/>
				<Button onClick={close} color="primary">閉じる</Button>
			</Box>
			<Toast mes={toast} close={closeToast}/>
		</Dialog>
	);
}

export default ConfigStatus;