import React from 'react';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FilterListIcon from '@mui/icons-material/FilterList';
import ListIcon from '@mui/icons-material/List';

interface ReportMenuProps {
	reset():void,
	filter():void,
}

const ReportMenu = (props:ReportMenuProps)=> {
	const [value, setValue] = React.useState(0);

	return (
		<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
		<BottomNavigation
		  showLabels
		  value={value}
		  onChange={(event, newValue) => {
			setValue(newValue);
			if( newValue===0 ){
				props.reset();
			} else {
				props.filter();
			}
		  }}
		>
		  <BottomNavigationAction label="ぜんぶ" icon={<ListIcon />} />
		  <BottomNavigationAction label="絞り込み" icon={<FilterListIcon />} />
		</BottomNavigation>
		</Paper>
	);
}

export default ReportMenu;
